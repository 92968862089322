import * as cx from "classnames/bind";
import { HomeLayout } from "layouts";
import Image from "next/legacy/image";
import Link from "next/link";
import Head from "next/head";
import { signIn, signOut } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import backgroundImage from "public/img/bartender1.jpg";
import { useForm } from "react-hook-form";
import s from "styles/login.module.scss";

import nextI18NextConfig from "../next-i18next.config.js";
import { useRouter } from "next/router";

export default function SignIn({ csrfToken }) {
  const { register, handleSubmit } = useForm();
  const { t } = useTranslation();
  const router = useRouter();

  const onSubmit = (data) => {
    signIn("credentials", {
      ...data,
      callbackUrl: router?.query?.callbackUrl || "/",
    });
  };

  return (
    <>
      <Head>
        <title key="title">Login - Cocktail Club</title>
        <meta
          key="ogTitle"
          property="og:title"
          content="Login - Cocktail Club"
        />
        <meta
          key="ogUrl"
          property="og:url"
          content="https://cocktailclub.com/login"
        />
        <link
          key="canonical"
          rel="canonical"
          href="https://cocktailclub.com/login"
        />
      </Head>
      <HomeLayout className="p0">
        <div className={cx("loginScreen b-beige", s.loginScreen)}>
          <div className="flex flex-wrap">
            <div className={cx("loginBanner split-item-50__d", s.loginBanner)}>
              <Image src={backgroundImage} alt="" />
            </div>
            <div className="container split-item-50__d py24">
              <h1 className="Heading Brick">
                {t("Welcome back! Get ready for some cocktails!")}
              </h1>

              <form
                id="loginPageForm"
                method="post"
                action={`/api/auth/callback/credentials?callbackUrl=${
                  router?.query?.callbackUrl || "/admin/products"
                }`}
                onSubmit={handleSubmit(onSubmit)}
              >
                <label>
                  {t("Email address")}
                  <input
                    name="email"
                    type="text"
                    {...register("email", { required: true })}
                  />
                </label>
                <label>
                  {t("Password")}
                  <input
                    name="password"
                    type="password"
                    {...register("password", { required: true })}
                  />
                </label>
                <input
                  type="submit"
                  className="Button Primary Full"
                  value={t("Sign in")}
                />
              </form>
              {/*
          <button
            className="Button"
            onClick={() => {
              signIn("facebook");
            }}
          >
            login with facebook
          </button>
          <button onClick={signOut}>logout</button>
          */}
              <div className="text-right mt8">
                <Link href="/profile/reset">{t("Lost your password?")}</Link>
              </div>
              <h2>{t("First time - want access?")}</h2>
              <Link href="/register" className={cx("Button Primary Full Blue")}>
                {t("Sign up - Free Access")}
              </Link>
            </div>
          </div>
        </div>
      </HomeLayout>
    </>
  );
}

export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, ["common"], nextI18NextConfig)),
  },
});
